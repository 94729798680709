import { useQuery } from "@tanstack/react-query";
import makeQueryRequest from "common/services/APIService/makeQueryRequest";
import { Response } from "common/services/APIService/types";
import { Connector } from "common/slices/connectors/connectors";
import { HOST } from "common/utils/constants";

const fetchConnectors = async (organisation_id: number | undefined): Promise<Response<any>> => {
  const response = await makeQueryRequest(`${HOST}/v1/connectors?organisation_id=${organisation_id}`);
  if (!response.success) {
    throw new Error(response.message);
  }
  return response;
};

export interface ConnectorAuthorisation {
  connector_type_id: number;
  connector_type_name: string;
  connector_category: string;
  created_at: string;
  created_by: string;
  id: number;
  organisation_id: number;
  remote_identifier?: string;
  updated_at: string;
  status: string;
}

export const fetchConnectorAuths = async (
  organisationId: number
): Promise<Response<{ connector_authorisations: ConnectorAuthorisation[] }>> => {
  return makeQueryRequest(
    `${HOST}/v1/connector_authorisations?organisation_id=${organisationId}`,
    {},
    { raisesError: true }
  );
};

const selectConnectors: (response: { data: { connectors: Connector[] } }) => Connector[] = (response) =>
  response.data?.connectors;

const connectorsV2 = {
  useConnectors: (currentOrganisationId: number | undefined) =>
    useQuery({
      queryKey: ["connectors", currentOrganisationId],
      queryFn: () => fetchConnectors(currentOrganisationId),
      select: selectConnectors,
      enabled: typeof currentOrganisationId === "number",
    }),
  useConnectorAuths: (currentOrganisationId: number | undefined) =>
    useQuery({
      queryKey: ["connectorAuths", currentOrganisationId],
      queryFn: () => fetchConnectorAuths(currentOrganisationId!),
      select: (data) => data.data.connector_authorisations,
      enabled: typeof currentOrganisationId === "number",
    }),
};

export default connectorsV2;
