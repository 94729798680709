import GAService from "common/services/GAService";
import { logoutUser } from "common/slices/auth";
import { submitConfig } from "common/slices/connectors/config";
import { inviteMembers } from "common/slices/members";
import { createOrganisationAndSetCookies } from "common/slices/organisations";
import { RootState } from "common/utils/store";
import { Middleware, MiddlewareAPI, Dispatch, AnyAction } from "redux";

const createEventTracker = (state: RootState) => {
  const publicOrganisationId = state.organisations?.currentOrganisation?.public_id || "";
  return new GAService.EventTracker({ publicOrganisationId });
};

const mapActionGAEvent: Record<string, (state: RootState, action: AnyAction) => void> = {
  [logoutUser.fulfilled.type]: (state: RootState, action: AnyAction) => {
    const eventTracker = createEventTracker(state);
    eventTracker.trackSignOut();
  },
  [inviteMembers.fulfilled.type]: (state: RootState, action: AnyAction) => {
    const eventTracker = createEventTracker(state);
    eventTracker.trackInviteMembers();
  },
  [createOrganisationAndSetCookies.fulfilled.type]: (state: RootState, action: AnyAction) => {
    const eventTracker = createEventTracker(state);
    eventTracker.trackOrganisationSetup();
  },
  [submitConfig.fulfilled.type]: (state: RootState, action: AnyAction) => {
    const eventTracker = createEventTracker(state);
    eventTracker.trackConnectorConfigured();
  },
};

const GAMiddleware: Middleware = (api: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
  const result = next(action);

  const gaEventTracker = mapActionGAEvent[action.type];

  if (!!gaEventTracker) gaEventTracker(api.getState(), action);

  return result;
};

export default GAMiddleware;
