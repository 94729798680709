/**
 * * WorkFlowStatusEnum is based on "customerweb-api".
 */
export enum WorkFlowStatusEnum {
  NOT_STARTED = "not_started",
  IN_PROGRESS = "in_progress",
  COMPLETE = "complete",
}

export type WorkFlowStatusResponse = { status: WorkFlowStatusEnum };
