import { REACT_APP_GA_TAG_ID } from "common/utils/constants";

import EventTracker from "./events";
import utils, { isInternalUser } from "./utils";

declare const gtag: (...args: (string | Record<string, unknown>)[]) => void;

const injectGAScript = () => {
  const scriptGA = document.createElement("script");
  scriptGA.src = `https://www.googletagmanager.com/gtag/js?id=${REACT_APP_GA_TAG_ID}`;
  scriptGA.async = true;
  document.head.appendChild(scriptGA);
};

const injectGA = () => {
  const scriptGACode = document.createElement("script");
  scriptGACode.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${REACT_APP_GA_TAG_ID}', {"send_page_view": false});
      `;
  document.head.appendChild(scriptGACode);
};

const configure = () => {
  if (!REACT_APP_GA_TAG_ID) return;
  injectGAScript();
  injectGA();

  if (isInternalUser()) gtag("set", "user_properties", { internal_user: true });
};

const configureUser = (userId: string) => {
  if (!REACT_APP_GA_TAG_ID || typeof gtag === "undefined") return;

  const userType = process.env.REACT_APP_BUILD_TARGET === "partner-app" ? "partner" : "org";
  gtag("config", REACT_APP_GA_TAG_ID, { user_id: userId, user_type: userType, send_page_view: false });
};

const GAService = {
  configure,
  configureUser,
  EventTracker,
  utils,
};

export { ProductSectionEnum } from "./types";

export default GAService;
