import makeRequest from "common/services/APIService/makeRequest";
import { HOST } from "common/utils/constants";

const billing = {
  createPortal: (data: { organisation_id?: number; public_id?: string }) =>
    makeRequest(`${HOST}/v1/create-customer-portal-session`, { method: "POST", body: data }),
  createCheckout: (data: { success_url: string; cancel_url: string; organisation_id: number; product_line: string }) =>
    makeRequest(`${HOST}/v1/create-customer-checkout-session`, { method: "POST", body: data }),
};

export default billing;
