import React from "react";

import "react-toastify/dist/ReactToastify.css"; // React toastify CSS Import
import "./common/scss/index.scss";
import AmplifyService from "app/services/AmplifyService";
import HubspotService from "app/services/HubspotService";
import GAService from "common/services/GAService";
import ReactDOM from "react-dom";

import reportWebVitals from "./app/reportWebVitals";

AmplifyService.configure();
GAService.configure();
HubspotService.configure();

function importBuildTarget(): Promise<{ default: React.ComponentType<any> }> {
  if (process.env.REACT_APP_BUILD_TARGET === "app") {
    return import("./app/App");
  } else if (process.env.REACT_APP_BUILD_TARGET === "partner-app") {
    return import("./partner-portal/PartnerApp");
  } else {
    return Promise.reject(new Error("No such build target: " + process.env.REACT_APP_BUILD_TARGET));
  }
}

importBuildTarget().then(({ default: Environment }) => {
  ReactDOM.render(
    <React.StrictMode>
      <Environment />
    </React.StrictMode>,
    document.getElementById("root")
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
