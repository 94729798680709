export enum GAEventActionEnum {
  SIGN_IN = "sign_in",
  SIGN_OUT = "sign_out",
  PAGE_VIEW = "page_view",
  CLICK = "click",
  SIGN_UP = "sign_up",
  SIGN_UP_PAGE_VIEW = "sign_up_page_view",
  ORGANISATION_SETUP = "organisation_setup",
  CONNECTOR_CONFIGURED = "connector_configured",
  INVITE_MEMBERS = "invite_members",
  LOG_ROCKET_SESSION = "log_rocket_session",
}

export enum GAEventCategoryEnum {
  PAGE = "page",
  USER = "user",
  INTERNAL_LINK = "internal_link",
  DROPDOWN = "dropdown",
  BUTTON = "button",
  CAROUSEL_SCROLL = "carousel_scroll",
  CHANGE_TAB = "change_tab",
  DATE_FILTER = "date_filter",
  EXTERNAL_LINK = "external_link",
  FEEDBACK = "feedback",
  DRAG_TO_SELECT = "drag_to_select",
}

export type EventTrackerType = {
  publicOrganisationId: string;
};

export type GAEventType = {
  action: GAEventActionEnum;
  category: GAEventCategoryEnum;
  label: string;
  payload: {
    public_org_id: string;
    product_section: ProductSectionEnum | string;
    [key: string]: string | number | boolean;
  };
};

export enum ProductSectionEnum {
  SIDE_BAR = "side bar",
  CONNECTOR_AUTH_TABLE = "connector auth table",
  ACTIONS_HEADER_FILTER = "action header filter",
  FEATURED_ACTION_CARD = "featured action card",
}
