import AmplifyService from "app/services/AmplifyService";

export enum HttpStatusCode {
  NOT_FOUND = 404,
}

type RequestParams = Omit<RequestInit, "body"> & {
  body?: Record<string, any>;
};

type Options = {
  auth?: boolean;
  json?: boolean;
  method?: HttpMethod;
  raisesError?: boolean; // When True, Enables error handling with react-query error states
};

export const enum HttpMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  PATCH = "PATCH",
  DELETE = "DELETE",
  OPTIONS = "OPTIONS",
  HEAD = "HEAD",
}

const makeQueryRequest = async (
  path: string,
  params: RequestParams = {},
  options: Options = {},
  headers: Record<string, string> = { "Content-Type": "application/json", Accept: "application/json" }
) => {
  const { auth = true, json = true, raisesError = false } = options;

  if (auth) {
    headers["Authorization"] = await AmplifyService.getToken();
  }

  const defaultRequestParams: RequestInit = {
    method: options.method ?? HttpMethod.GET,
    headers,
  };

  let body;
  if (params.body && headers["Content-Type"] === "application/json") {
    body = JSON.stringify(params.body);
  } else if (params.body && headers["Content-Type"] === "application/x-www-form-urlencoded") {
    body = new URLSearchParams(params.body);
  } else {
    body = undefined;
  }

  const response = await fetch(path, { ...defaultRequestParams, ...params, body });

  const isError = !response.ok;

  if (raisesError && isError) {
    const error = await response.json();
    return Promise.reject(error);
  }

  if (json) {
    return response.json();
  }
  return response;
};

export default makeQueryRequest;
