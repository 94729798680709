import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface DashboardSearchParams {
  dashboardId: string;
  dashboardSearch: Record<string, string | (string | null)[] | null>;
  dateRangesSearch: Record<string, string | (string | null)[] | null>;
}

export interface DashboardSearchState {
  dashboardSearchParams: Record<string, Record<string, string | (string | null)[] | null>>;
}

export const initialState: DashboardSearchState = {
  dashboardSearchParams: {},
};

export const dashboardSearchParamsSlice = createSlice({
  name: "dashboardSearchParams",
  initialState: initialState,
  reducers: {
    saveDashboardSearch(state: DashboardSearchState, action: PayloadAction<DashboardSearchParams>) {
      const { dashboardId, dashboardSearch, dateRangesSearch } = action.payload;
      state.dashboardSearchParams[dashboardId] = { ...state.dashboardSearchParams[dashboardId], ...dashboardSearch };
      state.dashboardSearchParams["dateRanges"] = { ...state.dashboardSearchParams["dateRanges"], ...dateRangesSearch };
    },
  },
});

export const { saveDashboardSearch } = dashboardSearchParamsSlice.actions;

export default dashboardSearchParamsSlice.reducer;
