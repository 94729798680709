export type EnvType = "production" | "staging" | "development";

export const REACT_APP_IDENTITY_POOL_ID = process.env.REACT_APP_IDENTITY_POOL_ID;
export const REACT_APP_REGION = process.env.REACT_APP_REGION;
export const REACT_APP_IDENTITY_POOL_REGION = process.env.REACT_APP_IDENTITY_POOL_REGION;
export const REACT_APP_USER_POOL_ID = process.env.REACT_APP_USER_POOL_ID;
export const REACT_APP_USER_POOL_WEB_CLIENT_ID = process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID;
export const REACT_APP_SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const REACT_APP_USER_POOL_DOMAIN = process.env.REACT_APP_USER_POOL_DOMAIN;
export const REACT_APP_LAUNCH_DARKLY_CLIENT_ID = process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID || "";
export const REACT_APP_INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID || "";
export const REACT_APP_ENV: EnvType = (process.env.REACT_APP_ENV as EnvType) || "development";
export const REACT_APP_WEBSOCKET_HOST = process.env.REACT_APP_WEBSOCKET_HOST || "";
export const REACT_APP_GA_TAG_ID = process.env.REACT_APP_GA_TAG_ID || "";
export const REACT_APP_HUBSPOT_TRACKING_ID = process.env.REACT_APP_HUBSPOT_TRACKING_ID || "";
export const REACT_APP_COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN || "conjura.com";
export const REACT_APP_FLATFILE_ENVIRONMENT_ID = process.env.REACT_APP_FLATFILE_ENVIRONMENT_ID || "";
export const HOST = process.env.REACT_APP_HOST;
export const TEST = process.env.TEST;
