import { useQuery } from "@tanstack/react-query";
import makeQueryRequest from "common/services/APIService/makeQueryRequest";
import { TransformationRunResponse } from "common/services/APIService/transformations/types";
import { Response } from "common/services/APIService/types";
import { HOST } from "common/utils/constants";

const fetchLastTransformationRun = async (organisationId: number): Promise<Response<TransformationRunResponse>> => {
  const response = await makeQueryRequest(`${HOST}/v1/transformation-runs?organisation_id=${organisationId}`);
  return response;
};

const queryKeys = {
  useTransformationRuns: (organisationId: number | undefined) => ["transformationRuns", organisationId],
};

const transformationRuns = {
  queryKeys,
  useTransformationRuns: (organisationId: number | undefined) =>
    useQuery({
      queryKey: queryKeys.useTransformationRuns(organisationId),
      // organisationId argument will always have a value here because of the enabled attribute.
      queryFn: () => fetchLastTransformationRun(organisationId!),
      enabled: !!organisationId,
    }),
};

export default transformationRuns;
