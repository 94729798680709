import { useMutation } from "@tanstack/react-query";
import makeQueryRequest, { HttpMethod } from "common/services/APIService/makeQueryRequest";
import {
  REACT_APP_USER_POOL_DOMAIN,
  REACT_APP_USER_POOL_WEB_CLIENT_ID,
  REACT_APP_COOKIE_DOMAIN,
  REACT_APP_ENV,
} from "common/utils/constants";

export interface OidcTokenResponse {
  id_token: string;
  access_token: string;
  refresh_token: string;
  expires_in: number;
  token_type: string;
}

const fetchAccessToken = async (code: string, domain: string, codeVerifier: string): Promise<OidcTokenResponse> => {
  const getCognitoTokenEndpoint = () => {
    switch (REACT_APP_ENV) {
      case "development":
        return `https://${REACT_APP_USER_POOL_DOMAIN}.auth.eu-west-1.amazoncognito.com/oauth2/token`;
      case "staging":
        return `https://login.staging.conjura.com/oauth2/token`;
      case "production":
        return `https://login.conjura.com/oauth2/token`;
    }
  };
  const response = await makeQueryRequest(
    getCognitoTokenEndpoint(),
    {
      method: HttpMethod.POST,
      body: {
        client_id: REACT_APP_USER_POOL_WEB_CLIENT_ID,
        redirect_uri: `https://${domain}.${REACT_APP_COOKIE_DOMAIN}/federated-login`,
        code: code,
        code_verifier: codeVerifier,
        grant_type: "authorization_code",
      },
    },
    { auth: false, raisesError: true },
    { "Content-Type": "application/x-www-form-urlencoded" }
  );
  return response;
};

const oidc = {
  useFetchAccessToken: ({
    onSuccess,
    onError,
  }: {
    onSuccess: (data: OidcTokenResponse) => void;
    onError: () => void;
  }) =>
    useMutation({
      mutationFn: (data: { code: string; domain: string; codeVerifier: string }) =>
        fetchAccessToken(data.code, data.domain, data.codeVerifier),
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError,
    }),
};

export default oidc;
