import { useQuery } from "@tanstack/react-query";
import makeQueryRequest from "common/services/APIService/makeQueryRequest";

const fetchUniversalDefinitions = async (locale: string): Promise<string> => {
  const response = await makeQueryRequest(`/markdown/definitions/${locale}.md`, {}, { json: false });
  return response.text();
};
const markdown = {
  useUniversalDefinitions: (locale: string) =>
    useQuery({
      queryKey: ["universalDefinitions"],
      queryFn: () => fetchUniversalDefinitions(locale),
    }),
};

export default markdown;
