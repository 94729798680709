import { useMutation, useQuery } from "@tanstack/react-query";
import { DashboardViewInput, DashboardViewOutput } from "app/services/APIService/dashboardViews/types";
import makeQueryRequest, { HttpMethod } from "common/services/APIService/makeQueryRequest";
import { Response } from "common/services/APIService/types";
import { HOST } from "common/utils/constants";

const fetchDashboardViews = async (organisationId: number, dashboardId: string): Promise<Response<any>> => {
  const response = makeQueryRequest(
    `${HOST}/v1/dashboard_views?organisation_id=${organisationId}&dashboard_id=${dashboardId}`,
    {},
    { raisesError: true }
  );
  return response;
};

const deleteDashboardView = async (organisationId: number, dashboardViewId: string): Promise<Response<any>> => {
  const response = makeQueryRequest(
    `${HOST}/v1/dashboard_views/${dashboardViewId}?organisation_id=${organisationId}`,
    {
      method: "DELETE",
    },
    { raisesError: true }
  );
  return response;
};

const createDashboardView = async (organisationId: number, data: DashboardViewOutput) => {
  await makeQueryRequest(
    `${HOST}/v1/dashboard_views?organisation_id=${organisationId}`,
    {
      method: HttpMethod.POST,
      body: data,
    },
    { raisesError: true }
  );
};

const selectDashboardViews: (
  response: { data: { dashboard_views: DashboardViewInput[] } },
  defaultDashboardView: DashboardViewInput
) => DashboardViewInput[] = (response, defaultDashboardView) => {
  return [defaultDashboardView, ...response.data?.dashboard_views];
};

const queryKeys = {
  fetch: (currentOrganisationId: number, dashboardId: string) => [
    "fetchDashboardViews",
    currentOrganisationId,
    dashboardId,
  ],
};

const dashboardViews = {
  queryKeys,
  fetch: (currentOrganisationId: number | undefined, dashboardId: string, defaultDashboardView: DashboardViewInput) =>
    useQuery({
      queryKey: queryKeys.fetch(currentOrganisationId!, dashboardId),
      queryFn: () => fetchDashboardViews(currentOrganisationId!, dashboardId),
      select: (data) => {
        return selectDashboardViews(data, defaultDashboardView);
      },
      enabled: typeof currentOrganisationId === "number",
    }),
  delete: (currentOrganisationId: number | undefined, onSuccess: () => void, onError: (error: any) => void) =>
    useMutation({
      mutationFn: (dashboard_view_id: string) => deleteDashboardView(currentOrganisationId!, dashboard_view_id),
      onSuccess: onSuccess,
      onError: onError,
    }),
  create: (currentOrganisationId: number, onSuccess: () => void, onError: (error: any) => void) =>
    useMutation({
      mutationFn: (data: DashboardViewOutput) => createDashboardView(currentOrganisationId, data),
      onSuccess: onSuccess,
      onError: onError,
    }),
};

export default dashboardViews;
