import { useQuery } from "@tanstack/react-query";
import makeQueryRequest from "common/services/APIService/makeQueryRequest";
import makeRequest from "common/services/APIService/makeRequest";
import { Response } from "common/services/APIService/types";
import { HOST } from "common/utils/constants";

import { WorkFlowStatusResponse } from "./types";

export const fetchWorkFlowStatus = async (
  organisationId: number | undefined
): Promise<Response<WorkFlowStatusResponse>> => {
  const response = await makeQueryRequest(`${HOST}/v1/workflows/status?organisation_id=${organisationId}`);
  return response;
};

const workflows = {
  launch: (organisation_id: number) =>
    makeRequest(`${HOST}/v1/workflows/launch?organisation_id=${organisation_id}`, { method: "POST" }), // TODO: Update with react-query
  useWorkFlowStatus: (organisationId: number | undefined) =>
    useQuery({
      queryKey: ["workFlowStatus", organisationId],
      queryFn: () => fetchWorkFlowStatus(organisationId),
      enabled: !!organisationId,
    }),
};

export { WorkFlowStatusEnum } from "./types";

export default workflows;
