import WebsocketHandler from "app/services/WebSocketAPIService/WebsocketHandler";

import useWebSocket from "./hooks/useWebSocket";
import { WebsocketRequest, WebsocketRequestState } from "./types";

export enum WebsocketRequestStatusEnum {
  REQUESTED = "requested",
  RECEIVED = "received",
  FAILED = "failed",
}

export type MetricRequestStatus = {
  metric?: string;
  status: WebsocketRequestStatusEnum;
};

type SendWebsocketMessageType = {
  uid: string;
  requests: { [key: string]: WebsocketRequestState };
  websocket: WebsocketHandler;
  payload: WebsocketRequest;
  cache?: boolean;
};

export const sendWebsocketMessage = ({
  uid,
  requests,
  websocket,
  payload,
  cache = false,
}: SendWebsocketMessageType): { [key: string]: WebsocketRequestState } => {
  if (cache && requests[uid] && requests[uid].status === WebsocketRequestStatusEnum.RECEIVED) {
    return requests;
  }

  websocket.sendAuthenticatedMessage({
    uid,
    payload,
  });

  requests[uid] = {
    requestPayload: payload,
    status: WebsocketRequestStatusEnum.REQUESTED,
  };
  return requests;
};

export const receiveWebsocketMessage = ({
  uid,
  requests,
  responsePayload,
}: {
  uid: string;
  requests: { [key: string]: WebsocketRequestState };
  responsePayload: any;
}): { [key: string]: WebsocketRequestState } => {
  if (requests[uid]) {
    requests[uid] = {
      ...requests[uid],
      status: WebsocketRequestStatusEnum.RECEIVED,
      responsePayload,
    };
  }
  return requests;
};

export { useWebSocket };
