import { useMutation } from "@tanstack/react-query";
import makeQueryRequest, { HttpMethod } from "common/services/APIService/makeQueryRequest";
import { Response } from "common/services/APIService/types";
import { HOST } from "common/utils/constants";

const launchWorkFlow = async (partnerId: number, organisationId: number): Promise<Response<null>> => {
  const response = await makeQueryRequest(
    `${HOST}/v1/partner-portal/workflows/launch?partner_id=${partnerId}&organisation_id=${organisationId}`,
    {
      method: HttpMethod.POST,
    },
    { raisesError: true }
  );
  return response;
};

const workflows = {
  useLaunchWorkFlow: ({
    partnerId,
    onSuccess,
    onError,
  }: {
    partnerId: number;
    onSuccess: (data: Response<null>) => void;
    onError: () => void;
  }) =>
    useMutation({
      mutationFn: (organisationId: number) => launchWorkFlow(partnerId, organisationId),
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError,
    }),
};

export default workflows;
