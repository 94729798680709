import { useEffect } from "react";

import WebsocketHandler, { JsonMessageListener } from "app/services/WebSocketAPIService/WebsocketHandler";
import websocket from "app/services/WebSocketAPIService/WebsocketHandler/websocket";

const useWebSocket = (messageListener: JsonMessageListener): WebsocketHandler => {
  useEffect(() => {
    websocket.addMessageListener(messageListener);

    return () => {
      websocket.removeMessageListener(messageListener);
    };
  }, []);

  return websocket;
};

export default useWebSocket;
