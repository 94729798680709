import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import APIService, { APICallState } from "app/services/APIService";
import { APIServiceFunctionArg } from "common/services/APIService/types";
import { AsyncThunkReturnType } from "common/slices/types";

export enum ConnectorCategory {
  // Types based upon conjura-transform/app/var_set_bases/var_set_bases.yml

  ERP = "erp",
  ECOMMERCE = "ecommerce",
  MARKETING = "marketing",
  WEB_ANALYTICS = "web_analytics",
  EMAIL = "email",
  ECOMMERCE_SUBSCRIPTION = "ecommerce_subscription",
}

// We receive { dispatch , getState} in the second argument of payloadCreator.
export const getConnectors = createAsyncThunk<
  AsyncThunkReturnType,
  APIServiceFunctionArg<typeof APIService.connectors.list>
>("connectors/getConnectors", async (organisation_id) => {
  return APIService.connectors.list(organisation_id).then((res) => res.json());
});

export const getConnectorAuth = createAsyncThunk<
  AsyncThunkReturnType,
  APIServiceFunctionArg<typeof APIService.connector_authorisations.get>
>("connectors/getConnectorAuth", async (data) => {
  return APIService.connector_authorisations.get(data).then((res) => res.json());
});

export const deleteConnectorAuth: any = createAsyncThunk<
  AsyncThunkReturnType,
  APIServiceFunctionArg<typeof APIService.connector_authorisations.deleteAuth>
>("connectors/deleteConnectorAuth", async (data) => {
  return APIService.connector_authorisations.deleteAuth(data).then((res) => res.json());
});

export const getConnectorAuths: any = createAsyncThunk<
  AsyncThunkReturnType,
  APIServiceFunctionArg<typeof APIService.connector_authorisations.list>
>("connectors/getConnectorAuths", async (organisation_id) => {
  return APIService.connector_authorisations.list(organisation_id).then((res) => res.json());
});

// Define a type for the slice state
export interface Connector {
  label: string;
  connector_type: string;
  connector_category: ConnectorCategory;
  airbyte_name: string;
  oauth_flow: boolean;
}

export interface ConnectorAuthorisation {
  connector_type_id: number;
  connector_type_name: string;
  connector_category: string;
  created_at: string;
  created_by: string;
  id: number;
  organisation_id: number;
  remote_identifier?: string;
  updated_at: string;
  status: string;
}

export interface ConnectorsState {
  connectors: Connector[];
  connector_authorisations: ConnectorAuthorisation[];
  connector_authorisation: ConnectorAuthorisation | undefined;
  status: string | undefined;
  getAuthStatus: string | undefined;
  listAuthStatus: string | undefined;
  deleteAuthStatus: string | undefined;
}

// Define the initial state using that type
export const initialState: ConnectorsState = {
  connectors: [],
  connector_authorisations: [],
  connector_authorisation: undefined,

  status: undefined,
  getAuthStatus: undefined,
  listAuthStatus: undefined,
  deleteAuthStatus: undefined,
};

export const connectorsSlice = createSlice({
  name: "connectors",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getConnectors.pending, (state) => {
      state.status = APICallState.LOADING;
    });

    builder.addCase(getConnectors.fulfilled, (state, action) => {
      state.status = APICallState.SUCCESS;
      state.connectors = action.payload?.data?.connectors || [];
    });

    builder.addCase(getConnectors.rejected, (state) => {
      state.status = APICallState.FAILED;
    });

    builder.addCase(getConnectorAuth.pending, (state) => {
      state.getAuthStatus = APICallState.LOADING;
    });

    builder.addCase(getConnectorAuth.fulfilled, (state, action) => {
      state.getAuthStatus = APICallState.SUCCESS;
      state.connector_authorisation = action.payload?.data?.connector_authorisation || undefined;
    });

    builder.addCase(getConnectorAuth.rejected, (state, action) => {
      state.getAuthStatus = APICallState.FAILED;
    });

    builder.addCase(deleteConnectorAuth.pending, (state) => {
      state.deleteAuthStatus = APICallState.LOADING;
    });

    builder.addCase(deleteConnectorAuth.fulfilled, (state, action) => {
      if (action.payload.status_code === 204) {
        state.deleteAuthStatus = APICallState.SUCCESS;
        state.connector_authorisations = state.connector_authorisations.filter(
          (connector_authorisation: any) => connector_authorisation.id !== action.meta.arg.connector_authorisation_id
        );
      } else {
        state.deleteAuthStatus = APICallState.FAILED;
      }
    });

    builder.addCase(deleteConnectorAuth.rejected, (state) => {
      state.deleteAuthStatus = APICallState.FAILED;
    });

    builder.addCase(getConnectorAuths.pending, (state) => {
      state.listAuthStatus = APICallState.LOADING;
    });

    builder.addCase(getConnectorAuths.fulfilled, (state, action) => {
      state.listAuthStatus = APICallState.SUCCESS;
      state.connector_authorisations = action.payload?.data?.connector_authorisations || [];
    });

    builder.addCase(getConnectorAuths.rejected, (state) => {
      state.listAuthStatus = APICallState.FAILED;
    });
  },
});

export default connectorsSlice.reducer;
