import makeRequest from "common/services/APIService/makeRequest";
import { HOST } from "common/utils/constants";

const snapshot = {
  getMetadata: ({ snapshot_id, organisation_id }: { snapshot_id: string; organisation_id: number }) =>
    makeRequest(`${HOST}/v1/snapshot/${snapshot_id}/metadata?organisation_id=${organisation_id}`),
  getLatest: ({ organisation_id }: { organisation_id: number }) =>
    makeRequest(`${HOST}/v1/snapshot/latest?organisation_id=${organisation_id}`),
  getSummary: ({ organisation_id }: { organisation_id: number }) =>
    makeRequest(`${HOST}/v1/snapshot/summary?organisation_id=${organisation_id}`),
};

export default snapshot;
