import * as Sentry from "@sentry/react";
import { formatLocale, formatSpecifier } from "d3";
import { ITimezoneOption } from "react-timezone-select";
import tinycolor from "tinycolor2";

const categories: Record<string, string[]> = require("common/assets/data/categories.json");
const currencies: Record<string, string[]> = require("common/assets/data/currencies.json");

const getDefaultValueSpecifier = (specifier: string) => {
  const defaultSpecifier = formatSpecifier(specifier);
  defaultSpecifier.type = "f";
  defaultSpecifier.trim = true;
  defaultSpecifier.precision = 2;
  return defaultSpecifier.toString();
};

export const getD3Locale = (localeOverrides: any) => {
  const baseLocale = {
    decimal: ".",
    thousands: ",",
    grouping: [3],
    currency: ["$", ""],
  };

  return {
    ...formatLocale({ ...baseLocale, ...localeOverrides }),
    format: (specifier: string) => {
      try {
        // https://github.com/eaglepointpartners/conjura/issues/19177
        // This function is for overriding the default si prefixes from the d3 format
        const locale = formatLocale({ ...baseLocale, ...localeOverrides });
        const formattedSpecifier = formatSpecifier(specifier);
        const valueFormatter = locale.format(specifier);
        const defaultValueFormatter = locale.format(getDefaultValueSpecifier(specifier));

        const d3SiPrefixMap = {
          y: "e-24",
          z: "e-21",
          a: "e-18",
          f: "e-15",
          p: "e-12",
          n: "e-9",
          µ: "e-6",
          m: "e-3",
          "": "",
          k: "K",
          M: "M",
          G: "B",
          T: "T",
          P: "P",
          E: "E",
          Z: "Z",
          Y: "Y",
        };
        return (value: number) => {
          if (formattedSpecifier.type === "s") {
            if (Math.abs(value) < 0.005) {
              return defaultValueFormatter(0);
            } else if (Math.abs(value) < 1000) {
              return defaultValueFormatter(value);
            } else {
              const result = valueFormatter(value);
              const lastChar = result[result.length - 1];
              if (Object.keys(d3SiPrefixMap).includes(lastChar)) {
                // @ts-ignore
                return result.slice(0, -1) + d3SiPrefixMap[lastChar];
              }
            }
          }
          return valueFormatter(value);
        };
      } catch (e) {
        return (value: number) => {
          console.warn(e);
          Sentry.captureException(e);
          return value.toString();
        };
      }
    },
    formatPrecision: (specifier: string) => {
      // This returns a function which can get a value rounded to the precision value of a format specifier.
      const { precision, type } = formatSpecifier(specifier);
      const allowedTypes = ["%", "f"];
      const getValue = (value: number, type: string) => {
        if (type === "%") {
          return value * 100;
        }
        return value;
      };

      // Example if specifier = ".1f" and value = 1.23456789, then the value returned is 1.2
      return (value: number) => {
        if (precision && allowedTypes.includes(type)) {
          const precisionValue = Math.pow(10, precision);
          return Math.round((getValue(value, type) + Number.EPSILON) * precisionValue) / precisionValue;
        }
        return value;
      };
    },
  };
};

export const truncate = (str: string, n: number) => {
  return str.length > n ? str.substr(0, n - 1) + "…" : str;
};

export const isObject = (data: any) => {
  return typeof data === "object" && !Array.isArray(data) && data !== null;
};

export const getReadableTime = (stringDateTime: string) => {
  const readableDateTime = new Date(stringDateTime);
  return `${readableDateTime.toLocaleDateString("en-GB")}, ${readableDateTime.toLocaleTimeString("en-GB")}`;
};

export const unionBenchmarkKey = (str1: string, str2: string, placeholder = "0", separator = "-") => {
  // This function is used to combine benchmark keys. example - "a-0-0-0" + "0-b2-0-0" = "a-b2-0-0"

  const list1 = str1.split(separator);
  const list2 = str2.split(separator);

  let i = list1.length - 1;
  let j = list2.length - 1;
  const unionList = [];
  while (i >= 0 && j >= 0) {
    unionList.unshift(list1[i] === placeholder ? list2[j] : list1[i]);
    i--;
    j--;
  }
  if (i >= 0) {
    while (i >= 0) {
      unionList.unshift(list1[i]);
      i--;
    }
  } else if (j >= 0) {
    while (j >= 0) {
      unionList.unshift(list2[j]);
      j--;
    }
  }
  return unionList.join(separator);
};

export const errorMessageParser = (message: string) => {
  message = message.replace("BadRequestError: ", "");
  try {
    return JSON.parse(message);
  } catch (e) {
    return message;
  }
};

export const capitalise = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getPercentageChange = (oldNumber: number, newNumber: number, round = 0, abs = false) => {
  const change = ((newNumber - oldNumber) / oldNumber) * 100;
  return abs ? Math.abs(change).toFixed(round) : change.toFixed(round);
};

export const debounce = (callback: (...args: any) => any, delay: number) => {
  /* This function is used to debounce events, for improving performance.
   Takes 2 parameters: the callback, and delay (in milli-seconds)
   */
  let timeout: NodeJS.Timeout;
  return function () {
    clearTimeout(timeout);
    timeout = setTimeout(callback, delay);
  };
};

export const getCategories = (currentCategory: string) => {
  const category = Object.keys(categories).map((category) => ({ const: category, title: category }));

  if (!currentCategory) category.unshift({ const: "", title: "Category" });
  return category;
};

export const getSubCategories = (currentCategory: string, currentSubcategory: string) => {
  const subCategory: { const: string; title: string }[] = [];
  if (!!currentCategory)
    categories[currentCategory].map((subcategory: string) => {
      subCategory.push({ const: subcategory, title: subcategory });
    });

  if (!currentSubcategory) subCategory.unshift({ const: "", title: "Subcategory" });
  return subCategory;
};

export const getCurrency = (currentCurrency: string) => {
  const currency = Object.keys(currencies).map((currency_code) => ({
    const: currency_code,
    title: `${currency_code} - ${currencies[currency_code]}`,
  }));

  if (!currentCurrency) currency.unshift({ const: "", title: "-" });
  return currency;
};

export const getTimezones = (timezoneOptions: ITimezoneOption[], currentTimezone: string) => {
  const timezones = timezoneOptions.map((option) => ({
    const: option.value,
    title: option.label,
  }));
  if (!currentTimezone) timezones.unshift({ const: "", title: "-" });
  return timezones;
};

export const getRgbColorStringFromHexCode = (hex: string) => {
  return tinycolor(hex).toRgbString().slice(4, -1).replaceAll(" ", "");
};

export const downloadFile = (url: string) => {
  const a = document.createElement("a");
  a.href = url;
  a.target = "_blank";
  const fileName = url.split("/").pop() as string;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
};

export const search = (searchKey: string, keywords: string[]) => {
  for (let i = 0; i < keywords.length; i++) {
    const keyword = keywords[i];
    if (keyword.toLowerCase().includes(searchKey.toLowerCase())) return true;
  }
  return false;
};

export const sha256 = async (str: string) => {
  return await crypto.subtle.digest("SHA-256", new TextEncoder().encode(str));
};

export const generateNonce = async () => {
  const hash = await sha256(crypto.getRandomValues(new Uint32Array(4)).toString());
  const hashArray = Array.from(new Uint8Array(hash));
  return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
};

export const base64URLEncode = (str: ArrayBuffer) => {
  // @ts-ignore
  return btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
};

export const isMarkdown = (data: string) => {
  // Check for common Markdown syntax patterns
  const markdownRegex = /[#*`\-[\]()>!+_|]/;

  // Return true if any Markdown syntax is found in the data
  return markdownRegex.test(data);
};
