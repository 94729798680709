import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Graph from "app/interfaces/graphs/Graph";
import APIService, { APICallState } from "app/services/APIService";
import { APIServiceFunctionArg } from "common/services/APIService/types";
import { AsyncThunkReturnType } from "common/slices/types";

export const getSummary = createAsyncThunk<
  AsyncThunkReturnType,
  APIServiceFunctionArg<typeof APIService.snapshot.getSummary>
>("summary/getSummary", async (data) => {
  return APIService.snapshot.getSummary(data).then((res) => res.json());
});

// Define a type for the slice state
export interface SnapshotSummaryState {
  graphs: Graph[] | undefined;
  status?: string | undefined;
}

export const initialState: SnapshotSummaryState = {
  graphs: undefined,
  status: undefined,
};

export const summarySlice = createSlice({
  name: "summary",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSummary.pending, (state: any) => {
      state.status = APICallState.LOADING;
      state.graphs = undefined;
    });
    builder.addCase(getSummary.fulfilled, (state: any, action: any) => {
      if (action.payload?.status_code == 200) {
        state.status = APICallState.SUCCESS;
        state.graphs = action.payload.data?.graphs;
      } else {
        state.status = APICallState.FAILED;
      }
    });
    builder.addCase(getSummary.rejected, (state: any) => {
      state.status = APICallState.FAILED;
    });
  },
});

export default summarySlice.reducer;
