import makeRequest from "common/services/APIService/makeRequest";
import { HOST } from "common/utils/constants";

const report = {
  get: ({
    snapshot_id,
    organisation_id,
    section,
    option,
    time_view,
  }: {
    snapshot_id: string;
    organisation_id: number;
    section: string;
    option: string;
    time_view: string;
  }) =>
    makeRequest(
      `${HOST}/v1/snapshot/${snapshot_id}/report?section=${section}&time_view=${time_view}&option=${option}&organisation_id=${organisation_id}`
    ),
};

export default report;
