import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import APIService, { APICallState } from "app/services/APIService";
import { APIServiceFunctionArg } from "common/services/APIService/types";
import { AsyncThunkReturnType } from "common/slices/types";
import { errorMessageParser } from "common/utils/helpers";

// We receive { dispatch , getState} in the second argument of payloadCreator.
export const getMembers = createAsyncThunk<AsyncThunkReturnType, APIServiceFunctionArg<typeof APIService.members.list>>(
  "members/getMembers",
  async (data) => {
    return APIService.members.list(data).then((res) => res.json());
  }
);

export const inviteMembers = createAsyncThunk<
  AsyncThunkReturnType,
  APIServiceFunctionArg<typeof APIService.members.invite>
>("members/inviteMembers", async (data) => {
  return APIService.members.invite(data).then((res) => res.json());
});

export const invalidateMember = createAsyncThunk<
  AsyncThunkReturnType,
  APIServiceFunctionArg<typeof APIService.members.invalidate>
>("members/invalidateMember", async (data) => {
  return APIService.members.invalidate(data).then((res) => {
    res.json();
    data.onFinish();
  });
});

export enum UserRoles {
  OWNER = "owner",
  EDITOR = "editor",
}

export interface UserAttributes {
  id: number;
  email: string;
  username: string;
}

export interface Member {
  id: number;
  organisation_id: number;
  user_attributes: UserAttributes;
  permissions: any[];
  roles: any[];
  created_at: string;
}

// Define a type for the slice state
export interface MembersState {
  members: Member[];
  status?: string | undefined;
  inviteErrorMessage?: any;
  inviteStatus?: string | undefined;
}

// Define the initial state using that type
export const initialState: MembersState = {
  members: [],
  status: undefined,
  inviteErrorMessage: undefined,
  inviteStatus: undefined,
};

export const membersSlice = createSlice({
  name: "members",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMembers.pending, (state) => {
      state.status = APICallState.LOADING;
    });

    builder.addCase(getMembers.fulfilled, (state, action) => {
      if (action.payload.status_code === 200) {
        state.status = APICallState.SUCCESS;
        state.members = action.payload.data.members;
      } else {
        state.status = APICallState.FAILED;
      }
    });

    builder.addCase(getMembers.rejected, (state) => {
      state.status = APICallState.FAILED;
    });

    builder.addCase(inviteMembers.pending, (state) => {
      state.inviteStatus = APICallState.LOADING;
      state.inviteErrorMessage = undefined;
    });

    builder.addCase(inviteMembers.fulfilled, (state, action) => {
      if (action.payload.status_code === 200) {
        state.inviteStatus = APICallState.SUCCESS;
      } else {
        state.inviteStatus = APICallState.FAILED;
        state.inviteErrorMessage = errorMessageParser(action.payload.message);
      }
    });

    builder.addCase(inviteMembers.rejected, (state) => {
      state.inviteStatus = APICallState.FAILED;
    });
  },
});

export default membersSlice.reducer;
