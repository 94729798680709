import { useQuery, useMutation } from "@tanstack/react-query";
import makeQueryRequest, { HttpMethod } from "common/services/APIService/makeQueryRequest";
import { Response, ErrorResponse } from "common/services/APIService/types";
import { HOST } from "common/utils/constants";

export interface SFTPCredentialsResponse {
  user_name: string;
  password: string;
  created_at: string;
}

interface CreateSFTPCredentialsResponse {
  user_name: string;
}

const fetchSFTPCredentials = async (partnerId: number): Promise<Response<SFTPCredentialsResponse>> => {
  const response = await makeQueryRequest(
    `${HOST}/v1/partner-portal/sftp_credentials?partner_id=${partnerId}`,
    {},
    { raisesError: true }
  );
  return response;
};

const createSFTPCredentials = async (partnerId: number): Promise<Response<CreateSFTPCredentialsResponse>> => {
  const response = await makeQueryRequest(
    `${HOST}/v1/partner-portal/sftp_credentials?partner_id=${partnerId}`,
    {
      method: HttpMethod.POST,
    },
    { raisesError: true }
  );
  return response;
};

const deleteSFTPCredentials = async (partnerId: number, userName: string): Promise<Response<null>> => {
  const response = await makeQueryRequest(
    `${HOST}/v1/partner-portal/sftp_credentials/${userName}?partner_id=${partnerId}`,
    {
      method: HttpMethod.DELETE,
    },
    { raisesError: true }
  );
  return response;
};

const queryKeys = {
  useSFTPCredentials: (partnerId: number) => ["sftpCredentials", partnerId],
};

const sftpCredentials = {
  queryKeys,
  useSFTPCredentials: (partnerId: number) =>
    useQuery({
      queryKey: queryKeys.useSFTPCredentials(partnerId),
      queryFn: () => fetchSFTPCredentials(partnerId),
      enabled: typeof partnerId === "number",
      onError: (err: ErrorResponse) => err,
      retry: false,
    }),
  useCreateSFTPCredentials: ({
    onSuccess,
    onError,
  }: {
    onSuccess: (data: Response<CreateSFTPCredentialsResponse>) => void;
    onError: () => void;
  }) =>
    useMutation({
      mutationFn: (partnerId: number) => createSFTPCredentials(partnerId),
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError,
    }),
  useDeleteSFTPCredentials: ({
    partnerId,
    onSuccess,
    onError,
  }: {
    partnerId: number;
    onSuccess: (data: Response<null>) => void;
    onError: () => void;
  }) =>
    useMutation({
      mutationFn: (userName: string) => deleteSFTPCredentials(partnerId, userName),
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError,
    }),
};

export default sftpCredentials;
