import { useQuery } from "@tanstack/react-query";
import makeQueryRequest from "common/services/APIService/makeQueryRequest";
import { Response } from "common/services/APIService/types";
import { HOST } from "common/utils/constants";

export interface User {
  email: string;
  email_verified: boolean;
  phone_number?: string;
  phone_number_verified?: boolean;
  daily_emails: boolean;
  locale: string;
  intercom_user_hash: string;
  username: string;
}

const fetchMe = async (): Promise<Response<{ user: User }>> => {
  return await makeQueryRequest(`${HOST}/v1/users/me`);
};

const queryKeys = {
  useMe: () => ["me"],
};

const usersV2 = {
  queryKeys,
  useMe: () =>
    useQuery({
      queryKey: queryKeys.useMe(),
      queryFn: () => fetchMe(),
      select: (data) => data.data.user,
    }),
};

export default usersV2;
