import { useQuery } from "@tanstack/react-query";
import GraphInterface from "app/interfaces/graphs/Graph";
import makeQueryRequest from "common/services/APIService/makeQueryRequest";
import { Response } from "common/services/APIService/types";
import { HOST } from "common/utils/constants";

const fetchSummary = async (
  organisation_id: number | undefined,
  timeView: string | undefined
): Promise<Response<any>> => {
  return await makeQueryRequest(`${HOST}/v1/snapshot/summary?organisation_id=${organisation_id}&time_view=${timeView}`);
};

const selectSummary: (response: { data: { graphs: GraphInterface[] } }) => GraphInterface[] = (response) =>
  response.data?.graphs;

const snapshotV2 = {
  useSummary: (currentOrganisationId: number | undefined, timeView: string | undefined) =>
    useQuery({
      queryKey: ["snapshotSummary", currentOrganisationId, timeView],
      queryFn: () => fetchSummary(currentOrganisationId, timeView),
      select: selectSummary,
      enabled: Boolean(timeView) && typeof currentOrganisationId === "number",
    }),
};

export default snapshotV2;
