import { useQuery } from "@tanstack/react-query";
import makeQueryRequest from "common/services/APIService/makeQueryRequest";
import { Response } from "common/services/APIService/types";
import { HOST } from "common/utils/constants";

import { OrganisationsResponse, FetchOrganisationsQueryParams } from "./types";

const fetchOrganisations = async ({
  partnerId,
  page,
  pageSize,
  name,
  appAccess,
}: FetchOrganisationsQueryParams): Promise<Response<any>> => {
  let url = `${HOST}/v1/partner-portal/organisations?partner_id=${partnerId}&page=${page}&page_size=${pageSize}&app_access=${appAccess}`;
  if (!!name) url += `&name=${name}`;
  const response = await makeQueryRequest(url);
  if (!response.success) {
    throw new Error(response.message);
  }
  return response;
};

const selectOrganisationsAndTotalPages: (response: { data: OrganisationsResponse }) => OrganisationsResponse = (
  response
) => response.data;

const organisations = {
  useOrganisations: (queryParams: FetchOrganisationsQueryParams) =>
    useQuery({
      queryKey: [
        "organisations",
        queryParams.partnerId,
        queryParams.page,
        queryParams.pageSize,
        queryParams.name,
        queryParams.appAccess,
      ],
      queryFn: () => fetchOrganisations(queryParams),
      select: selectOrganisationsAndTotalPages,
      enabled: typeof queryParams.partnerId === "number",
    }),
};

export default organisations;
