import { GAEventType, EventTrackerType, GAEventActionEnum, GAEventCategoryEnum } from "./types";

declare const gtag: (...args: (string | Record<string, unknown>)[]) => void;

const sendGAEvent = (event: GAEventType) => {
  if (typeof gtag === "undefined") return;
  gtag("event", event.action, {
    event_category: event.category,
    event_label: event.label,
    ...event.payload,
  });
};

class EventTracker {
  private publicId: string;

  constructor({ publicOrganisationId }: EventTrackerType) {
    this.publicId = publicOrganisationId;
  }

  private getCommonPayload() {
    return {
      public_org_id: this.publicId,
    };
  }

  trackSignIn(success: boolean) {
    sendGAEvent({
      action: GAEventActionEnum.SIGN_IN,
      category: GAEventCategoryEnum.USER,
      label: "Sign In" + success ? "Successful" : "Fail",
      payload: {
        ...this.getCommonPayload(),
        product_section: "sign in form",
        sign_in_success: success,
      },
    });
  }

  trackSignOut() {
    sendGAEvent({
      action: GAEventActionEnum.SIGN_OUT,
      category: GAEventCategoryEnum.USER,
      label: "Sign Out Successful",
      payload: {
        ...this.getCommonPayload(),
        product_section: "based on logout actions",
      },
    });
  }

  trackSignUp() {
    sendGAEvent({
      action: GAEventActionEnum.SIGN_UP,
      category: GAEventCategoryEnum.USER,
      label: "Sign Up Successful",
      payload: {
        ...this.getCommonPayload(),
        product_section: "sign up email verification form",
      },
    });
  }

  trackConnectorConfigured() {
    sendGAEvent({
      action: GAEventActionEnum.CONNECTOR_CONFIGURED,
      category: GAEventCategoryEnum.USER,
      label: "Connector Configured Successful",
      payload: {
        ...this.getCommonPayload(),
        product_section: "submit config form success",
      },
    });
  }

  trackPageView(params: { pageTitle: string; pageLocation: string }) {
    sendGAEvent({
      action: GAEventActionEnum.PAGE_VIEW,
      category: GAEventCategoryEnum.PAGE,
      label: "Page View",
      payload: {
        ...this.getCommonPayload(),
        product_section: "browser window",
        ...params,
      },
    });
  }

  trackSignUpPageView() {
    sendGAEvent({
      action: GAEventActionEnum.SIGN_UP_PAGE_VIEW,
      category: GAEventCategoryEnum.PAGE,
      label: "Sign Up Page View",
      payload: {
        ...this.getCommonPayload(),
        product_section: "sign up page",
      },
    });
  }

  trackLogRocketSession(logRocketUrl: string) {
    sendGAEvent({
      action: GAEventActionEnum.LOG_ROCKET_SESSION,
      category: GAEventCategoryEnum.PAGE,
      label: "Log Rocket Session",
      payload: {
        ...this.getCommonPayload(),
        product_section: "log rocket session",
        log_rocket_url: logRocketUrl,
      },
    });
  }

  trackInviteMembers() {
    sendGAEvent({
      action: GAEventActionEnum.INVITE_MEMBERS,
      category: GAEventCategoryEnum.USER,
      label: "Invite Members Successful",
      payload: {
        ...this.getCommonPayload(),
        product_section: "invite member form success",
      },
    });
  }

  trackOrganisationSetup() {
    sendGAEvent({
      action: GAEventActionEnum.ORGANISATION_SETUP,
      category: GAEventCategoryEnum.USER,
      label: "Organisation Setup Successful",
      payload: {
        ...this.getCommonPayload(),
        product_section: "organisation setup form success",
      },
    });
  }

  trackInternalLinkClick(label: string, productSection: string) {
    sendGAEvent({
      action: GAEventActionEnum.CLICK,
      category: GAEventCategoryEnum.INTERNAL_LINK,
      label: label,
      payload: {
        ...this.getCommonPayload(),
        product_section: productSection,
      },
    });
  }

  trackButtonClick(label: string, productSection: string) {
    sendGAEvent({
      action: GAEventActionEnum.CLICK,
      category: GAEventCategoryEnum.BUTTON,
      label: label,
      payload: {
        ...this.getCommonPayload(),
        product_section: productSection,
      },
    });
  }

  trackDropdownClick(label: string, productSection: string) {
    sendGAEvent({
      action: GAEventActionEnum.CLICK,
      category: GAEventCategoryEnum.DROPDOWN,
      label: label,
      payload: {
        ...this.getCommonPayload(),
        product_section: productSection,
      },
    });
  }

  trackChangeTabClick(label: string, productSection: string) {
    sendGAEvent({
      action: GAEventActionEnum.CLICK,
      category: GAEventCategoryEnum.CHANGE_TAB,
      label: label,
      payload: {
        ...this.getCommonPayload(),
        product_section: productSection,
      },
    });
  }

  trackDateFilterClick(label: string, productSection: string) {
    sendGAEvent({
      action: GAEventActionEnum.CLICK,
      category: GAEventCategoryEnum.DATE_FILTER,
      label: label,
      payload: {
        ...this.getCommonPayload(),
        product_section: productSection,
      },
    });
  }

  trackExternalLinkClick(label: string, productSection: string) {
    sendGAEvent({
      action: GAEventActionEnum.CLICK,
      category: GAEventCategoryEnum.EXTERNAL_LINK,
      label: label,
      payload: {
        ...this.getCommonPayload(),
        product_section: productSection,
      },
    });
  }

  trackFeedbackClick(label: string, productSection: string) {
    sendGAEvent({
      action: GAEventActionEnum.CLICK,
      category: GAEventCategoryEnum.FEEDBACK,
      label: label,
      payload: {
        ...this.getCommonPayload(),
        product_section: productSection,
      },
    });
  }
}

export default EventTracker;
