import { useQuery } from "@tanstack/react-query";
import makeQueryRequest from "common/services/APIService/makeQueryRequest";
import { Response } from "common/services/APIService/types";
import { ConnectorAuthorisation } from "common/slices/connectors/connectors";
import { HOST } from "common/utils/constants";

const fetchConnectorsAuths = async (organisation_id: number | undefined): Promise<Response<any>> => {
  const response = await makeQueryRequest(`${HOST}/v1/connector_authorisations?organisation_id=${organisation_id}`);
  if (!response.success) {
    throw new Error(response.message);
  }
  return response;
};

const selectConnectorAuths: (response: {
  data: { connector_authorisations: ConnectorAuthorisation[] };
}) => ConnectorAuthorisation[] = (response) => response.data?.connector_authorisations;

const connectorAuthorisationsV2 = {
  useConnectorAuths: (currentOrganisationId: number | undefined) =>
    useQuery({
      queryKey: ["connectorAuthorisations", currentOrganisationId],
      queryFn: () => fetchConnectorsAuths(currentOrganisationId),
      select: selectConnectorAuths,
      enabled: typeof currentOrganisationId === "number",
    }),
};

export default connectorAuthorisationsV2;
