import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import APIService, { APICallState, APICallStateType } from "app/services/APIService";
import { APIServiceFunctionArg } from "common/services/APIService/types";
import { AsyncThunkReturnType } from "common/slices/types";

export const launchWorkflow = createAsyncThunk<
  AsyncThunkReturnType,
  APIServiceFunctionArg<typeof APIService.workflows.launch>
>("workflows/launch", async (organisation_id) => {
  return APIService.workflows.launch(organisation_id).then((res) => res.json());
});

export interface WorkflowState {
  status: APICallStateType;
  submitStatus: APICallStateType;
}

// Define the initial state using that type
export const initialState: WorkflowState = {
  status: undefined,
  submitStatus: undefined,
};

export const workflowSlice = createSlice({
  name: "workflow",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(launchWorkflow.pending, (state: any) => {
      state.submitStatus = APICallState.LOADING;
    });
    builder.addCase(launchWorkflow.fulfilled, (state: any, action: any) => {
      if (action.payload.status_code == 200) {
        state.submitStatus = APICallState.SUCCESS;
      } else {
        state.submitStatus = APICallState.FAILED;
      }
    });
    builder.addCase(launchWorkflow.rejected, (state: any) => {
      state.submitStatus = APICallState.FAILED;
    });
  },
});

export default workflowSlice.reducer;
