import { Amplify, Auth } from "aws-amplify";
import { Locale } from "common/i18n/locales";
import CookieService, { CookieNames } from "common/services/CookieService";
import {
  REACT_APP_COOKIE_DOMAIN,
  REACT_APP_ENV,
  REACT_APP_IDENTITY_POOL_ID,
  REACT_APP_IDENTITY_POOL_REGION,
  REACT_APP_REGION,
  REACT_APP_USER_POOL_ID,
  REACT_APP_USER_POOL_WEB_CLIENT_ID,
} from "common/utils/constants";
const errorCodes = {
  UserNotConfirmedException: "UserNotConfirmedException",
};

const configure = () => {
  Amplify.configure({
    Auth: {
      identityPoolId: REACT_APP_IDENTITY_POOL_ID,
      region: REACT_APP_REGION,
      identityPoolRegion: REACT_APP_IDENTITY_POOL_REGION,
      userPoolId: REACT_APP_USER_POOL_ID,
      userPoolWebClientId: REACT_APP_USER_POOL_WEB_CLIENT_ID,
      mandatorySignIn: true,
      cookieStorage: {
        domain: REACT_APP_COOKIE_DOMAIN,
        secure: REACT_APP_ENV == "development" ? false : true,
        path: "/",
        expires: 1,
      },
    },
  });
};

export type UserResponsePromise = {
  user?: any;
  success: boolean;
  message?: string;
  code?: string;
};

export type SignUpAttributes = {
  username: string;
  password: string;
  attributes: {
    email: string;
    phone_number: string;
  };
};

export type ConfirmSignUpAttributes = {
  username: string;
  code: string;
};

async function signUpUser({ username, password, attributes }: SignUpAttributes): Promise<UserResponsePromise> {
  try {
    const { user } = await Auth.signUp({
      username,
      password,
      attributes,
    });
    console.log(user);
    return { user, success: true };
  } catch (err: any) {
    console.log("error signing up:", err);
    return { success: false, message: err.message };
  }
}

async function confirmSignUp({ username, code }: ConfirmSignUpAttributes) {
  try {
    const { user } = await Auth.confirmSignUp(username, code);
    console.log(user);
    return { user, success: true };
  } catch (err: any) {
    console.log("error signing up:", err);
    return { success: false, message: err.message };
  }
}

const getUser = async (): Promise<UserResponsePromise> => {
  let user: any;
  try {
    user = await Auth.currentAuthenticatedUser();
    console.log("User: ", user);
    return { user, success: true };
  } catch (err: any) {
    console.log("Unable to get user. Error: ", err);
    return { success: false, message: err.message };
  }
};

async function signOutUser() {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log("error signing out: ", error);
  }
}

async function resendConfirmationCode(username: string) {
  try {
    await Auth.resendSignUp(username);
    console.log("code resent successfully");
  } catch (err) {
    console.log("error resending code: ", err);
  }
}

async function signInUser(username: string, password: string): Promise<UserResponsePromise> {
  try {
    const user = await Auth.signIn(username, password);
    return { user, success: true };
  } catch (err: any) {
    console.log("error signing in", err);
    if (err.code === errorCodes.UserNotConfirmedException) {
      await resendConfirmationCode(username);
    }
    return { success: false, message: err.message, code: err.code };
  }
}

async function forgotPassword(username: string) {
  try {
    await Auth.forgotPassword(username);
    console.log("code resent successfully");
    return { success: true };
  } catch (err: any) {
    console.log("error resending code: ", err);
    return { success: false, message: err.message };
  }
}

async function forgotPasswordSubmit(username: string, code: string, password: string) {
  try {
    await Auth.forgotPasswordSubmit(username, code, password);
    console.log("Reset password successful");
    return { success: true };
  } catch (err: any) {
    console.log("Error resetting password: ", err);
    return { success: false, message: err.message };
  }
}

async function getToken() {
  try {
    const currentSession = await Auth.currentSession();
    return currentSession.getIdToken().getJwtToken();
  } catch (e) {
    return CookieService.get(CookieNames.ID_TOKEN);
  }
}

type Boolean = "true" | "false";

function toBoolean(value: boolean) {
  return value ? "true" : "false";
}

async function updateAttributes(attributes: { "custom:daily_emails"?: Boolean }) {
  const user = await Auth.currentAuthenticatedUser();
  await Auth.updateUserAttributes(user, attributes);
}

async function updateLocale(locale: Locale) {
  const user = await Auth.currentAuthenticatedUser();
  await Auth.updateUserAttributes(user, {
    locale,
  });
}

const AmplifyService = {
  configure,
  signUpUser,
  confirmSignUp,
  getUser,
  signOutUser,
  signInUser,
  resendConfirmationCode,
  forgotPassword,
  forgotPasswordSubmit,
  getToken,
  updateLocale,
  updateAttributes,
  toBoolean,
  errorCodes,
};

export default AmplifyService;
