import metadataReducer from "common/slices/snapshot/metadata";
import reportReducer from "common/slices/snapshot/report";
import summaryReducer from "common/slices/snapshot/summary";
import { combineReducers } from "redux";

export default combineReducers({
  metadata: metadataReducer,
  report: reportReducer,
  summary: summaryReducer,
});
