import makeRequest from "common/services/APIService/makeRequest";

const makeNoAuthRequest = async (
  path: Parameters<typeof makeRequest>[0],
  params: Parameters<typeof makeRequest>[1] = {}
) => {
  return makeRequest(path, params, false);
};

export default makeNoAuthRequest;
