import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Graph from "app/interfaces/graphs/Graph";
import APIService, { APICallState } from "app/services/APIService";
import { APIServiceFunctionArg } from "common/services/APIService/types";
import { AsyncThunkReturnType } from "common/slices/types";

export const enum TimeViewsEnum {
  yearly = "yearly",
  monthly = "monthly",
  weekly = "weekly",
  daily = "daily",
}

export const getReport = createAsyncThunk<AsyncThunkReturnType, APIServiceFunctionArg<typeof APIService.report.get>>(
  "report/getReport",
  async (data) => {
    return APIService.report.get(data).then((res) => res.json());
  }
);

// Define a type for the slice state
export interface SnapshotReportState {
  graphs: Graph[] | undefined;
  status?: string | undefined;
  currentRequestId: string | undefined;
}

export const initialState: SnapshotReportState = {
  graphs: undefined,
  status: undefined,
  currentRequestId: undefined,
};

export const reportSlice = createSlice({
  name: "report",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getReport.pending, (state, action) => {
      state.status = APICallState.LOADING;
      state.graphs = undefined;
      state.currentRequestId = action.meta.requestId;
    });
    builder.addCase(getReport.fulfilled, (state, action) => {
      if (state.currentRequestId !== action.meta.requestId) return;
      if (action.payload?.status_code == 200) {
        state.status = APICallState.SUCCESS;
        state.graphs = action.payload.data?.graphs;
      } else {
        state.status = APICallState.FAILED;
      }
    });
    builder.addCase(getReport.rejected, (state) => {
      state.status = APICallState.FAILED;
    });
  },
});

export default reportSlice.reducer;
