import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import APIService, { APICallState } from "app/services/APIService";
import { APIServiceFunctionArg } from "common/services/APIService/types";
import { AsyncThunkReturnType } from "common/slices/types";

export const getSnapshotMetadata = createAsyncThunk<
  AsyncThunkReturnType,
  APIServiceFunctionArg<typeof APIService.snapshot.getMetadata>
>("snapshot/getSnapshotMetadata", async (data) => {
  return APIService.snapshot.getMetadata(data).then((res) => res.json());
});

export const getLatestSnapshot = createAsyncThunk<
  AsyncThunkReturnType,
  APIServiceFunctionArg<typeof APIService.snapshot.getLatest>
>("snapshot/getLatestSnapshot", async (data) => {
  return APIService.snapshot.getLatest(data).then((res) => res.json());
});

export interface Snapshot {
  id: string;
  status: "initiated" | "finished";
  created_at: string;
  updated_at: string;
}

export interface SnapshotMetadata {
  organisation_name: string;
  vertical: string;
  overall_score?: {
    numerator: number;
    denominator: number;
  };
  benchmark_options: {
    [key: string]: any;
  };
  benchmark_options_ui_schema: {
    [key: string]: any;
  };
  benchmark_sizes: { id: string; size: number }[];
  sections: { label: string; value: string; section_tooltip: string }[];
  time_views?: { [key: string]: string[] };
}

// Define a type for the slice state
export interface SnapshotMetadataState {
  latestSnapshot: Snapshot | undefined;
  metadata: SnapshotMetadata | undefined;
  status?: string | undefined;
}

// Define the initial state using that type
export const initialState: SnapshotMetadataState = {
  latestSnapshot: undefined,
  metadata: undefined,
  status: undefined,
};

export const snapshotMetadataSlice = createSlice({
  name: "snapshotMetadata",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSnapshotMetadata.pending, (state) => {
      state.status = APICallState.LOADING;
    });

    builder.addCase(getSnapshotMetadata.fulfilled, (state, action) => {
      if (action.payload?.status_code == 200) {
        state.status = APICallState.SUCCESS;
        state.metadata = action.payload.data;
      } else {
        state.status = APICallState.FAILED;
      }
    });

    builder.addCase(getSnapshotMetadata.rejected, (state) => {
      state.status = APICallState.FAILED;
    });

    builder.addCase(getLatestSnapshot.pending, (state) => {
      state.status = APICallState.LOADING;
    });

    builder.addCase(getLatestSnapshot.fulfilled, (state, action) => {
      if (action.payload?.status_code == 200) {
        state.status = APICallState.SUCCESS;
        const { metadata, ...latestSnapshot } = action.payload.data;
        state.metadata = metadata;
        state.latestSnapshot = latestSnapshot;
      } else {
        state.status = APICallState.FAILED;
      }
    });

    builder.addCase(getLatestSnapshot.rejected, (state) => {
      state.status = APICallState.FAILED;
    });
  },
});

export default snapshotMetadataSlice.reducer;
