import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import APIService, { APICallState } from "app/services/APIService";
import { receiveWebsocketMessage, sendWebsocketMessage } from "app/services/WebSocketAPIService";
import {
  WebsocketServerMessageType,
  WebsocketRequestAction,
  WebsocketRequestState,
} from "app/services/WebSocketAPIService/types";
import { APIServiceFunctionArg } from "common/services/APIService/types";
import { AsyncThunkReturnType } from "common/slices/types";

export const getDashboardsList = createAsyncThunk<
  AsyncThunkReturnType,
  APIServiceFunctionArg<typeof APIService.dashboards.list>
>("metrics/dashboardList", async (organisationId) => {
  return APIService.dashboards.list(organisationId).then((res) => res.json());
});

export interface DashboardListItem {
  id: string;
  label: string;
  tags: string[];
}
export interface MetricsState {
  metrics: Record<string, WebsocketRequestState>;
  filters: Record<string, WebsocketRequestState>;
  exports: Record<string, WebsocketRequestState>;
  dashboardList: DashboardListItem[];
  dashboardListStatus: string | undefined;
}
// Define the initial state using that type
export const initialState: MetricsState = {
  metrics: {},
  filters: {},
  exports: {},
  dashboardList: [],
  dashboardListStatus: undefined,
};

export const metricsSlice = createSlice({
  name: "metrics",
  initialState: initialState,
  reducers: {
    requestMetrics(state, action: PayloadAction<WebsocketRequestAction>) {
      const { uid, payload, websocket } = action.payload;
      state.metrics = sendWebsocketMessage({ uid, websocket, payload, requests: state.metrics, cache: true });
    },
    receiveMetrics(state, action: PayloadAction<WebsocketServerMessageType>) {
      const { payload, uid } = action.payload;
      state.metrics = receiveWebsocketMessage({ uid, requests: state.metrics, responsePayload: payload });
    },
    requestExport(state, action: PayloadAction<WebsocketRequestAction>) {
      const { uid, payload, websocket } = action.payload;
      state.exports = sendWebsocketMessage({ uid, websocket, payload, requests: state.exports, cache: true });
    },
    receiveExport(state, action: PayloadAction<WebsocketServerMessageType>) {
      const { payload, uid } = action.payload;
      state.exports = receiveWebsocketMessage({ uid, requests: state.exports, responsePayload: payload });
    },
    requestFilters(state, action: PayloadAction<WebsocketRequestAction>) {
      const { uid, payload, websocket } = action.payload;
      state.filters = sendWebsocketMessage({ uid, websocket, payload, requests: state.filters, cache: true });
    },
    receiveFilters(state, action: PayloadAction<WebsocketServerMessageType>) {
      const { payload, uid } = action.payload;
      state.filters = receiveWebsocketMessage({ uid, requests: state.filters, responsePayload: payload });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDashboardsList.pending, (state) => {
      state.dashboardListStatus = APICallState.LOADING;
    });
    builder.addCase(getDashboardsList.fulfilled, (state, action) => {
      if (action.payload.status_code == 200) {
        state.dashboardListStatus = APICallState.SUCCESS;
        state.dashboardList = action.payload?.data?.dashboards || [];
      } else {
        state.dashboardListStatus = APICallState.FAILED;
        state.dashboardList = [];
      }
    });
    builder.addCase(getDashboardsList.rejected, (state) => {
      state.dashboardListStatus = APICallState.FAILED;
    });
  },
});

export const { requestMetrics, receiveMetrics, requestExport, receiveExport, requestFilters, receiveFilters } =
  metricsSlice.actions;

export default metricsSlice.reducer;
